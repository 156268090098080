export type BaseGroup = {
  id: number;
  name: string;
  url: string;
  is_admin: boolean;
  avatar: string;
};

export type BaseSubmission = {
  id: number;
  url: string;
  group: BaseGroup;
};

export interface DocumentGroup extends BaseGroup {
  membership_id: number;
  credits_available: number;
  credits_required: number;
  max_words: number;
}

export type BaseDocument = {
  id: number;
  name: string;
  submissions: BaseSubmission[] | null;
  url: string;
  title: string;
  word_count: number;
  groups_available: DocumentGroup[];
};

export type BaseProject = {
  id: number;
  title: string;
  documents?: BaseDocument[];
};

export type BaseNotification = {
  id: number;
  message: string;
  dismiss_url: string;
};

export type Membership = {
  user: number;
  group: BaseGroup;
  balance: number;
  active: boolean;
  last_active: string;
  last_click_on_review_tab: string;
  expand_discussion: boolean;
  notifications: BaseNotification[];
};

export type BaseInvitation = {
  id: number;
};

export type BaseNote = {
  id: number;
  title: string;
  contents: string;
};

export type Genre = {
  name: string;
  children: {
    id: number;
    name: string;
    parent: number;
    selected: boolean;
    children: {
      id: number;
      name: string;
      parent: number;
      selected: boolean;
    }[];
  };
};

export type BaseUser = {
  id: number;
  pen_name: string;
};

export interface Profile extends BaseUser {
  avatar: string;
  memberships: Membership[];
  genres2_set: Genre[];
  critiquing_genres2_set: Genre[];
  publication_set: Publication[];
}

export type Publication = {
  title: string;
  publisher: string;
  year: number;
  image: string;
  url: string;
};

export type BaseEvent = {
  id: number;
  start_date: string;
  end_date: string;
  status: string;
  title: string;
  type: string;
  event_type: string;
  link: string;
  free_to_members: boolean;
  non_members_can_join: boolean;
  event_category: { id: number; name: string }[];
};

export interface Event extends BaseEvent {
  image_url: string;
  description: string;
}

export interface Author {
  id: string;
  name: string;
}

export interface Book {
  id: string;
  title: string;
}

export interface Organization {
  id: string;
  name: string;
}

export type FullUserProfile = {
  id: number;
  user: number;
  pen_name: string;
  is_searching: boolean;
  // get_searching_for_group_display: string;
  avatar: any;
  bio: string;
  is_illustrator: boolean;
  favorite_authors: any;
  favorite_books: any;
  experience: string;
  non_writing_experience: string;
  searching_for_group: number;
  looking_in_a_group: string;
  looking_for_in_critique: string;
  critiquing_style: string;
  organizations: any;
  hide_from_search: boolean;
  city: string;
  state: string;
  country: string;
  facebook: string;
  linkedin: string;
  goodreads: string;
  twitter: string;
  instagram: string;
  personal_website: string;
  pronouns: string;
  publication_set: Publication[];
  quote_set: {
    author: string;
    text: string;
  }[];
  genres2_set: {
    name: string;
    children: any;
  }[];
  critiquing_genres2_set: {
    name: string;
    children: any;
  }[];
  favoriteAuthors: Author[];
  favoriteBooks: Book[];
  organnizations: Organization[];
  writing_sample: string;
};

export type PaginationInfo = {
  current?: number;
  total?: number;
};

export type PaginationLinks = {
  next: string | null;
  previous: string | null;
};

export interface BasePaginatedResponse extends PaginationLinks {
  count: number;
  results: any[];
}

export type BaseMessage = {
  id: number;
  sender: string;
  recipient: string;
  subject: string;
  read: boolean;
  sent_at: string;
};

type CurrentUserMembership = {
  id: number;
  notifications: [];
  group: {
    id: number;
    name: string;
    url: string;
    is_admin: boolean;
    avatar: string;
    description: string;
  };
  balance: number;
  active: boolean;
  last_active: string;
  last_click_on_review_tab: string;
  expand_discussion: boolean;
};

export type CurrentUser = {
  id: number;
  pen_name: string;
  avatar: string;
  unread_messages: number;
  memberships: CurrentUserMembership[];
  show_dashboard_intro: boolean;
  show_group_intro: boolean;
  has_active_subscription: boolean;
  is_in_free_trial_period: boolean;
};

export type CurrentUserState = {
  isAuthenticated: boolean;
  data: CurrentUser;
  loading: boolean;
};

export type Annotation = {
  id: number;
  permissions: { read: []; update: []; delete: []; admin: [] };
  user: { id: number; pen_name: string };
  ranges: {
    start: string;
    startOffset: number;
    end: string;
    endOffset: number;
  }[];
  quote: string;
  text?: string;
  created?: string;
};

export enum CritiqueStatus {
  DRAFT = 1,
  FINISHED_PENDING_APPROVAL = 2,
  FINISHED_APPROVED = 3,
  FINISHED_REJECTED = 4,
}

export type Critique = {
  id?: number;
  author: number;
  contents: string;
  created_at: string;
  // emails_submission: any
  finished_at: string;
  reopened_at: string;
  status: CritiqueStatus;
  submission: number;
};
