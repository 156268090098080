import config from "config";
import { BaseUser } from "types";
import { marginViewer } from "./annotator-margin-viewer";
import { mediumBreakpoint } from "../../constants";

export function initAnnotator(
  user: BaseUser,
  submission_id: number,
  element: HTMLDivElement,
  token: string,
  all: boolean = false,
  allUsers?: BaseUser[],
  canDelete: boolean = true
) {
  const Annotator = require("annotator");
  var app = new Annotator.App();
  app.include(Annotator.ui.main, { element: element });

  app.include(Annotator.storage.http, {
    prefix: `${config.BASE_API_URL}api/v1.0/note/`,
    urls: all
      ? {
          read: `${submission_id}/Submission/allnotes/`,
          search: `${submission_id}/Submission/allnotes/`,
        }
      : {
          create: `${submission_id}/${user.id}/Submission/note/`,
          read: `${submission_id}/${user.id}/Submission/note/:id`,
          update: `{id}`,
          destroy: `{id}`,
          search: `${submission_id}/${user.id}/Submission/note/`,
        },
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  if (mediumBreakpoint <= window.innerWidth) {
    app.include(marginViewer, {
      user: { ...user, can_delete: canDelete },
      user_list: allUsers ? allUsers : [user],
      app: app,
    });
  }

  app.start().then(function () {
    app.annotations.load();
  });

  return app;
}
