import { Annotation } from "types";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";

const DATA_HIGHLIGHT_STARTS = "data-highlight-starts";
const DATA_HIGHLIGHT_ENDS = "data-highlight-ends";

function getElementByXpath(path: string, base: Node = document) {
  return document.evaluate(
    path,
    base,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue;
}

interface FullAnnotation extends Annotation {
  _marginObject: HTMLElement | null;
  _local: {
    highlights: HTMLSpanElement[];
  };
  _marginindex: number;
}

type MarginViewerOptions = {
  user: {
    id: number;
    pen_name: string;
    can_delete: boolean;
  };
  shortenEnabled: boolean;
  user_list: {
    id: number;
    pen_name: string;
  }[];
  app: any;
};

type Location = {
  top: number;
  left: number;
};

export function marginViewer(options: MarginViewerOptions) {
  const app = options.app;
  const displacement = -17;
  let data: { location: Location; annotation: FullAnnotation }[] = [];
  let insertions = 0;
  let deletions = 0;
  let annotations: Record<string, FullAnnotation> = {};
  let unfilteredAnnotationsList: FullAnnotation[] = [];
  let currentSelectedAnnotation: FullAnnotation | null = null;

  const sign = function (x: number) {
    if (x === 0) {
      return 0;
    } else {
      return x / Math.abs(x);
    }
  };

  const objectEquals = function (obj1: FullAnnotation, obj2: FullAnnotation) {
    if ("id" in obj1 && "id" in obj2) {
      return obj1.id === obj2.id;
    }
    if ("id" in obj1 || "id" in obj2) {
      return false;
    }
    if ("_marginindex" in obj1 && "_marginindex" in obj2) {
      return obj1["_marginindex"] === obj2["_marginindex"];
    }
    return false;
  };

  const getObjectLocation = function (annotation: FullAnnotation) {
    var _i;
    if (annotation === undefined) {
      debugger;
    }
    const supposedLocation = annotation._marginindex;
    if (
      supposedLocation < data.length &&
      objectEquals(data[supposedLocation].annotation, annotation)
    ) {
      return supposedLocation;
    }
    const minimumIndex = Math.max(0, supposedLocation - deletions);
    const maximumIndex = Math.min(
      data.length - 1,
      supposedLocation + insertions
    );
    for (
      let index = (_i = minimumIndex);
      minimumIndex <= maximumIndex ? _i <= maximumIndex : _i >= maximumIndex;
      index = minimumIndex <= maximumIndex ? ++_i : --_i
    ) {
      if (typeof data[index] == "undefined") {
        return -1;
      }
      const currentObjectAnnotation = data[index].annotation;
      if (objectEquals(currentObjectAnnotation, annotation)) {
        currentObjectAnnotation._marginindex = index;
        return index;
      }
    }
    return -1;
  };

  const getNewAbsoluteOffsetsIfObjectMovesToPosition = function (
    absoluteTop: number,
    obj: HTMLElement
  ) {
    const gap = 5;
    let lastObjectAbsoluteTop = absoluteTop - gap;
    let preLocationChanges: [number, HTMLElement][] = [];
    let objectAbsoluteNewTop = absoluteTop;
    const allMarginElements = $(
      ".annotator-marginviewer-element"
    ).toArray() as HTMLElement[];
    const objectIndex = allMarginElements.indexOf(obj);
    let currentIndex = objectIndex - 1;
    // calculate locations for objects before the current selection in reverse order
    while (currentIndex >= 0) {
      const currentObject = allMarginElements[currentIndex];
      const currentObjectSize = currentObject.offsetHeight;
      const currentObjectAbsoluteCurrentTop = currentObject.offsetTop;
      const currentObjectAbsoluteBottom =
        currentObjectAbsoluteCurrentTop + currentObjectSize;
      // const currentObjectAbsoluteGoalLocation = paramFuncObject.sortDataMap(
      //   currentObjectAnnotation
      // );
      // const currentObjectAbsoluteAnnotationTop =
      //   currentObjectAbsoluteGoalLocation.top;
      // const currentObjectAbsoluteAnnotationBottom =
      //   currentObjectAbsoluteAnnotationTop + currentObjectSize;

      currentIndex -= 1;

      if (currentObjectAbsoluteBottom > lastObjectAbsoluteTop) {
        // move object above the last one
        const newAbsoluteTop = lastObjectAbsoluteTop - currentObjectSize;
        preLocationChanges = [
          [newAbsoluteTop, currentObject],
          ...preLocationChanges,
        ];
        lastObjectAbsoluteTop = newAbsoluteTop - gap;
        continue;
      }

      // if (currentObjectAbsoluteAnnotationTop >= currentObjectAbsoluteCurrentTop) {
      //   continue;
      // }

      // if (currentObjectAbsoluteAnnotationBottom < lastObjectAbsoluteTop) {
      //   objectAbsoluteNewTop = currentObjectAbsoluteAnnotationTop;
      //   locationChanges.push([objectAbsoluteNewTop, currentObject]);
      //   lastObjectAbsoluteTop = currentObjectAbsoluteAnnotationTop;
      //   continue;
      // }
      // objectAbsoluteNewTop = lastObjectAbsoluteTop - currentObjectSize;
      // locationChanges.push([objectAbsoluteNewTop, currentObject]);
      // lastObjectAbsoluteTop = objectAbsoluteNewTop;
      // continue;
    }

    // calculate locations for objects after the current selection
    currentIndex = objectIndex + 1;
    let lastObjectAbsoluteBottom = absoluteTop + obj.offsetHeight + gap;

    let postLocationChanges: [number, HTMLElement][] = [];

    while (currentIndex < allMarginElements.length) {
      const currentObject = allMarginElements[currentIndex];
      const currentObjectSize = currentObject.offsetHeight;
      const currentObjectAbsoluteTop = currentObject.offsetTop;
      // const currentObjectAbsoluteGoalLocation = paramFuncObject.sortDataMap(
      //   currentObjectAnnotation
      // );
      // const currentObjectAbsoluteGoalTop =
      //   currentObjectAbsoluteGoalLocation.top;

      currentIndex += 1;

      if (currentObjectAbsoluteTop < lastObjectAbsoluteBottom) {
        objectAbsoluteNewTop = lastObjectAbsoluteBottom;
        postLocationChanges.push([objectAbsoluteNewTop, currentObject]);
        lastObjectAbsoluteBottom =
          objectAbsoluteNewTop + currentObjectSize + gap;
        continue;
      }
      // if (currentObjectAbsoluteGoalTop >= currentObjectAbsoluteTop) {
      //   continue;
      // }
      // if (currentObjectAbsoluteGoalTop > lastObjectAbsoluteBottom) {
      //   objectAbsoluteNewTop = currentObjectAbsoluteGoalTop;
      //   locationChanges.push([objectAbsoluteNewTop, currentObject]);
      //   lastObjectAbsoluteBottom = objectAbsoluteNewTop + currentObjectSize;
      //   continue;
      // }

      // objectAbsoluteNewTop = lastObjectAbsoluteBottom;
      // locationChanges.push([objectAbsoluteNewTop, currentObject]);
      // lastObjectAbsoluteBottom = objectAbsoluteNewTop + currentObjectSize;
    }
    return [preLocationChanges, postLocationChanges];
  };

  const paramFuncObject = {
    sortDataMap: function (annotation: FullAnnotation) {
      let dbg: Location;
      const highlight = annotation._local.highlights[0];
      const offset = highlight
        ? {
            top: highlight.offsetTop + displacement,
            left: highlight.offsetLeft,
          }
        : null;
      if (offset === null) {
        dbg = {
          top: 0,
          left: 0,
        };
      } else {
        dbg = {
          top: offset.top,
          left: offset.left,
        };
      }

      return dbg;
    },
    sortComparison: function (left: FullAnnotation, right: FullAnnotation) {
      const leftTop = left._local.highlights[0].offsetTop;
      const rightTop = right._local.highlights[0].offsetTop;
      const leftLeft = left._local.highlights[0].offsetLeft;
      const rightLeft = right._local.highlights[0].offsetLeft;
      if (sign(leftTop - rightTop) == 0) {
        return sign(leftLeft - rightLeft);
      } else {
        return sign(sign(leftTop - rightTop));
      }
      //        return sign(sign(left.top - right.top) * 2 + sign(left.left - right.left) * RTL_MULT);
    },
    idFunction: function (annotation: FullAnnotation) {
      return annotation.id;
    },
    sizeFunction: function (element: HTMLSpanElement) {
      // return element.outerHeight(true);
      return element.offsetHeight;
    },
  };

  const funcObject = {
    mapFunc: (annotation: FullAnnotation) => {
      return {
        location: paramFuncObject.sortDataMap(annotation),
        annotation: annotation,
      };
    },
  };

  function findIndexForNewObject(annotation: FullAnnotation) {
    var currentIndex, endIndex, startIndex;
    startIndex = 0;
    endIndex = data.length;
    for (
      currentIndex = startIndex;
      currentIndex < endIndex;
      currentIndex += 1
    ) {
      if (
        paramFuncObject.sortComparison(
          annotation,
          data[currentIndex].annotation
        ) < 0
      ) {
        break;
      }
    }
    return currentIndex;
  }

  const overlappedObjects = function (marginObject: HTMLElement) {
    // var marginObject = obj.closest(".annotator-marginviewer-element");
    var all = document
      .querySelector(".secondary")
      ?.querySelectorAll(".annotator-marginviewer-element");

    const starts = parseFloat(
      marginObject?.getAttribute(DATA_HIGHLIGHT_STARTS) || "0"
    );
    const ends = parseFloat(
      marginObject?.getAttribute(DATA_HIGHLIGHT_ENDS) || "0"
    );

    var overppedObjects: HTMLElement[] = [];
    all?.forEach((otherObject) => {
      const otherStarts = parseFloat(
        otherObject.getAttribute(DATA_HIGHLIGHT_STARTS) || "0"
      );
      const otherEnds = parseFloat(
        otherObject.getAttribute(DATA_HIGHLIGHT_ENDS) || "0"
      );
      if (
        otherObject != marginObject &&
        ((otherStarts <= starts && otherEnds >= starts) ||
          (otherStarts <= ends && otherEnds >= ends))
      ) {
        overppedObjects.push(otherObject as HTMLElement);
      }
    });
    return overppedObjects;
  };

  function addNewObjectToData(annotation: FullAnnotation) {
    const newObjectIndex = findIndexForNewObject(annotation);
    // increment index for annotations after the new object
    data.slice(newObjectIndex).forEach((x) => {
      x.annotation._marginindex += 1;
    });
    // insert the new object
    data.splice(newObjectIndex, 0, funcObject.mapFunc(annotation));
    annotation._marginindex = newObjectIndex;
    return (insertions += 1);
  }

  // create an object with a color for each user id in options.user_list
  let colorDict: Record<number, string> = {};
  options.user_list.forEach((user, index) => {
    colorDict[user.id] = (index + 1).toString();
  });

  const secondaryMarginDiv = document.getElementsByClassName("secondary")[0];

  const wrapper = document.getElementsByClassName(
    "secondary margin-annotator-container"
  )[0] as HTMLElement;
  const wrapperOffset = wrapper.offsetTop;

  function renderMarginObject(
    annotation: FullAnnotation,
    top: number | undefined,
    hide: boolean
  ): JSX.Element {
    const datetime = annotation.created ? annotation.created : "";
    const user = options.user;
    const delel =
      user && (annotation.user.id == user.id || user.can_delete) ? (
        <>
          <span
            className="annotator-marginviewer-delete"
            style={{ float: "right", direction: "ltr" }}
          ></span>
          <span
            className="annotator-marginviewer-edit"
            style={{ float: "right", direction: "ltr" }}
          ></span>
        </>
      ) : (
        <></>
      );
    const color_index =
      annotation.user && typeof colorDict != undefined
        ? colorDict[annotation.user.id]
        : "1";
    const colored_css_class = "annotator-hl-color-" + color_index;
    const highlight = annotation._local.highlights[0];
    const style = {
      top: top,
      display: highlight && !hide ? "block" : "none",
    };
    if (highlight) {
      annotation._local.highlights[0].classList.add(colored_css_class);
    }

    const text = options.shortenEnabled
      ? annotation.text?.substring(0, 120)
      : annotation.text;

    return (
      <div className="annotator-marginviewer-element" style={style}>
        <div className="arrow"></div>
        <div
          className={`annotator-marginviewer-color annotator-marginviewer-color-${color_index}`}
        ></div>
        <div className="annotator-marginviewer-text-wrapper">
          {delel}
          <div className="annotator-marginviewer-text">{text}</div>
        </div>
        <div className="annotator-marginviewer-header">
          <span
            className="annotator-marginviewer-date"
            style={{ float: "right", direction: "ltr" }}
          >
            {datetime}
          </span>
          <span className="annotator-marginviewer-user">
            {annotation.user.pen_name}
          </span>
        </div>
      </div>
    );
  }
  const updateObjectLocation = function (annotation: FullAnnotation) {
    const objIndex = getObjectLocation(annotation);
    data[objIndex] = {
      location: paramFuncObject.sortDataMap(annotation),
      annotation: annotation,
    };
    return (annotation._marginindex = objIndex);
  };

  function getRelativeTopForElementAndAbsoluteTop(
    element: HTMLElement,
    newAbsoluteTop: number
  ) {
    const marginElements = $(
      ".annotator-marginviewer-element"
    ).toArray() as HTMLElement[];
    let addedHeights = 0;
    let len = 0;
    for (let el of marginElements) {
      if (el.dataset.annotationId == element.dataset.annotationId) {
        break;
      }
      addedHeights += el.offsetHeight;
      len += 1;
    }
    return newAbsoluteTop + displacement - wrapperOffset - addedHeights;
  }

  function moveObjectToPosition(
    newAbsoluteTop: number,
    element: HTMLElement,
    marginRight: number | string
  ) {
    const delay = 300;
    const currentAbsoluteTop = element.offsetTop;
    const currentRelativeTop = parseFloat(element.style.top.replace("px", ""));
    const delta = newAbsoluteTop - currentAbsoluteTop;
    const newRelativeTop = currentRelativeTop + delta;
    element.animate(
      {
        top: `${newRelativeTop}px`,
        marginRight: marginRight + "px",
      },
      {
        duration: delay,
        easing: "ease-in",
      }
    );
    setTimeout(() => {
      element.style["top"] = `${newRelativeTop}px`;
      element.style["marginRight"] = marginRight + "px";
    }, delay);
  }

  const moveObjectsToNewPosition = (
    newLocations: [number, HTMLElement][],
    horizontalSlideObjects: [number, string, HTMLElement][],
    newLocations2: [number, HTMLElement][]
  ) => {
    if (horizontalSlideObjects == null) {
      horizontalSlideObjects = [];
    }
    // move elements before the new location
    for (let _i = 0, _len = newLocations.length; _i < _len; _i++) {
      const newLocationStructure = newLocations[_i];
      const newAbsoluteTop = newLocationStructure[0];
      if (!newLocationStructure) {
        continue;
      }
      const currentObject = newLocationStructure[1];
      moveObjectToPosition(newAbsoluteTop, currentObject, 0);
      const annotationId = parseInt(
        currentObject.getAttribute("data-annotation-id") || "-1"
      );
      const annotation = annotations[annotationId]
        ? annotations[annotationId]
        : unfilteredAnnotationsList.filter((a) => a.id == annotationId)[0];
      updateObjectLocation(annotation);
    }
    //  move the selected object
    const _results = [];
    for (let _j = 0, _len1 = horizontalSlideObjects.length; _j < _len1; _j++) {
      const horizontalSlide = horizontalSlideObjects[_j];
      const newAbsoluteTop = horizontalSlide[0];
      const newMarginRight = horizontalSlide[1];
      const currentObject = horizontalSlide[2];
      moveObjectToPosition(newAbsoluteTop, currentObject, newMarginRight);
      const annotation =
        annotations[currentObject.getAttribute("data-annotation-id") || -1];
      _results.push(updateObjectLocation(annotation));
    }
    // move objects after the new location
    for (let _j = 0, _len = newLocations2.length; _j < _len; _j++) {
      const newLocationStructure = newLocations2[_j];
      if (!newLocationStructure) {
        continue;
      }
      const newAbsoluteTop = newLocationStructure[0];
      const currentObject = newLocationStructure[1];
      moveObjectToPosition(newAbsoluteTop, currentObject, 0);
      const annotation =
        annotations[currentObject.getAttribute("data-annotation-id") || -1];
      updateObjectLocation(annotation);
    }
    return _results;
  };

  function onMarginEdited(marginObject: HTMLElement) {
    const annotation =
      annotations[marginObject?.getAttribute("data-annotation-id") || 0];
    const highlight = annotation._local.highlights[0];
    const editor = $(".annotator-outer.annotator-editor")[0];
    editor.style.top = `${highlight.offsetTop}px`;
    editor.style.left = `${highlight.offsetLeft}px`;
    return app.annotations.update(annotation);
  }

  function onMarginDeleted(marginObject: HTMLElement) {
    const annotation =
      annotations[marginObject?.getAttribute("data-annotation-id") || 0];
    if ($(marginObject).hasClass("annotator-marginviewer-selected")) {
      if (window.confirm("Are you sure you want to delete this comment?")) {
        $(marginObject).remove();
        annotation._marginObject = null;
        console.log(app);
        delete annotations[annotation.id];
        data = data.filter((x) => x.annotation.id !== annotation.id);
        data.forEach((row, idx) => {
          row.annotation._marginindex = idx;
        });
        return app.annotations.delete(annotation);
      } else {
        return false;
      }
    }
  }

  function cleanUpClasses() {
    const allMarginElements = $(".annotator-marginviewer-element").toArray();
    Object.values(annotations).forEach((annotation) => {
      const color = annotation._marginObject?.getAttribute("data-color");
      annotation._local.highlights.forEach((highlight) => {
        highlight.classList.add("annotator-hl");
        highlight.classList.add("annotator-hl-color-" + color);

        highlight.classList.remove("annotator-hl-uber");
        highlight.classList.remove("annotator-hl-uber-temp");
        highlight.className = highlight.className
          .split(" ")
          .filter((className) => {
            return !(
              className.startsWith("annotator-hl-uber-color-") ||
              className.startsWith("annotator-hl-uber-temp-color-")
            );
          })
          .join(" ");
      });
    });
    allMarginElements.forEach((element) => {
      element.classList.remove("annotator-marginviewer-selected");
      element.classList.remove("annotator-marginviewer-semi-selected");
    });
  }

  function onMarginSelected(obj: HTMLElement | null) {
    let marginObject = obj?.closest(
      ".annotator-marginviewer-element"
    ) as HTMLElement;
    const color = marginObject?.getAttribute("data-color");
    const annotationId = marginObject?.getAttribute("data-annotation-id");
    const annotation = annotations[annotationId || 0];
    if (!annotation) {
      return;
    }
    const refetchedObj = $(
      `.annotator-marginviewer-element[data-annotation-id='${annotationId}']`
    );
    marginObject = refetchedObj[0] as HTMLElement;

    let horizontalSlide: [number, string, HTMLElement][] = [];
    const highlight = annotation._local.highlights[0];
    const newAbsoluteTopForCurrentObject = highlight.offsetTop + displacement;
    const newAbsoluteBottomForCurrentObject =
      marginObject.offsetHeight + newAbsoluteTopForCurrentObject;
    let [newObsoluteOffsetsByObject1, newAbsoluteOffsetsByObject2] =
      getNewAbsoluteOffsetsIfObjectMovesToPosition(
        newAbsoluteTopForCurrentObject,
        marginObject
      );
    let overlapped: HTMLElement[] = [];
    if (currentSelectedAnnotation !== null) {
      if (annotation.id === currentSelectedAnnotation.id) {
        return;
      }
      cleanUpClasses();
      // const currentMarginObject = $(wrapper).find(
      //   '[data-annotation-id="' + currentSelectedAnnotation.id + '"]'
      // )[0];
      // if (currentMarginObject) {
      //   var cur_color = currentMarginObject.getAttribute("data-color");
      //   currentSelectedAnnotation._local.highlights.forEach(function (
      //     highlight: HTMLElement
      //   ) {
      //     highlight.classList.remove("annotator-hl-uber");
      //     highlight.classList.remove("annotator-hl-uber-color-" + cur_color);
      //     highlight.classList.remove(
      //       "annotator-hl-uber-temp-color-" + cur_color
      //     );
      //     highlight.classList.remove("annotator-hl-uber-temp");
      //     highlight.classList.add("annotator-hl");
      //     highlight.classList.add("annotator-hl-color-" + cur_color);
      //   });
      //   const filteredClasses = highlight.className
      //     .split(" ")
      //     .filter(function (className) {
      //       // exclude if it's in a list of classes
      //       return (
      //         [
      //           "annotator-hl-uber",
      //           "annotator-hl-uber-color-" + cur_color,
      //           "annotator-hl-uber-temp-color-" + cur_color,
      //           "annotator-hl-uber-temp",
      //         ].indexOf(className) === -1
      //       );
      //     });
      //   highlight.className =
      //     filteredClasses.join(" ") +
      //     "annotator-hl annotator-hl-color-" +
      //     cur_color;
      //   overlapped = overlappedObjects(currentMarginObject);
      //   overlapped.forEach(function (overlappedObject: HTMLElement) {
      //     overlappedObject.classList.remove(
      //       "annotator-marginviewer-semi-selected"
      //     );
      //   });
      // --------------------
      // let currentObjectAbsoluteNewTop = currentMarginObject.offsetTop;
      // horizontalSlide.push([
      //   currentObjectAbsoluteNewTop,
      //   "+=20px",
      //   currentMarginObject,
      // ]);
      // --------------------
      // }
    }

    // move current object to the new position
    horizontalSlide.push([
      newAbsoluteTopForCurrentObject,
      "-=10px",
      marginObject,
    ]);

    moveObjectsToNewPosition(
      newObsoluteOffsetsByObject1,
      horizontalSlide,
      newAbsoluteOffsetsByObject2
    );

    annotation._local.highlights.forEach(function (highlight: HTMLElement) {
      highlight.classList.add("annotator-hl-uber");
      highlight.classList.add("annotator-hl-uber-color-" + color);
      highlight.classList.remove("annotator-hl");
      highlight.classList.remove("annotator-hl-color-" + color);
    });
    marginObject.classList.add("annotator-marginviewer-selected");
    marginObject.classList.add("annotator-marginviewer-semi-selected");
    overlapped.forEach(function (overlappedObject: HTMLElement) {
      overlappedObject.classList.add("annotator-marginviewer-semi-selected");
    });

    currentSelectedAnnotation = annotation;
    return annotation;
  }

  function onMarginMouseIn(marginObject: EventTarget | null) {
    return marginObject;
  }

  function onMarginMouseOut(marginObject: EventTarget | null) {
    return marginObject;
  }

  function insertAtIndex(element: HTMLElement, i?: number) {
    if (i === undefined) {
      $(wrapper).append(element);
      return;
    }

    if (i === 0) {
      $(wrapper).prepend(element);
      return;
    }
    $(".secondary.margin-annotator-container > div:nth-child(" + i + ")").after(
      element
    );
  }

  function createMarginObject(
    annotation: FullAnnotation,
    top?: number,
    hide?: boolean,
    index?: number
  ) {
    const newMarginObject = renderMarginObject(annotation, top, !!hide);
    insertAtIndex($(ReactDOMServer.renderToString(newMarginObject))[0], index);
    const effectiveIndex =
      index === undefined ? secondaryMarginDiv.children.length - 1 : index;
    const marginObject: HTMLElement = secondaryMarginDiv.children[
      effectiveIndex
    ] as HTMLElement;

    document.addEventListener("click", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginSelected(marginObject);
      }
    });

    marginObject.addEventListener("mouseenter", (event) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginMouseIn(marginObject);
      }
    });
    marginObject.addEventListener("mouseleave", (event) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginMouseOut(marginObject);
      }
    });
    if (annotation.user.id == options.user.id || options.user.can_delete) {
      $(marginObject)
        .find(".annotator-marginviewer-delete")[0]
        .addEventListener("click", (event) => {
          onMarginDeleted(marginObject);
        });

      $(marginObject)
        .find(".annotator-marginviewer-edit")[0]
        .addEventListener("click", (event) => {
          onMarginEdited(marginObject);
        });
    }
    annotation._marginObject = marginObject;
    annotation._marginindex = effectiveIndex;
    // marginObject.annotation = annotation;

    annotation._local.highlights.forEach(function (highlight: HTMLElement) {
      highlight.addEventListener("click", (event) => {
        onMarginSelected(marginObject);
      });
    });
    const highlight = annotation._local.highlights[0];
    const offsetTop = highlight ? highlight.offsetTop : 0;
    // set data attributes
    const starts = offsetTop + displacement;
    const height = highlight ? highlight.offsetHeight : 0;
    const ends = starts + height;
    annotations[annotation.id.toString()] = annotation;
    const color_index =
      annotation.user && typeof colorDict != undefined
        ? colorDict[annotation.user.id]
        : "1";
    marginObject?.setAttribute(DATA_HIGHLIGHT_STARTS, starts.toString());
    marginObject?.setAttribute("data-highlight-height", height.toString());
    marginObject?.setAttribute(DATA_HIGHLIGHT_ENDS, ends.toString());
    marginObject?.setAttribute("data-annotation-id", annotation.id.toString());
    marginObject?.setAttribute("data-color", color_index.toString());
    return marginObject;
  }

  function initializeAnnotations(annotations: FullAnnotation[]) {
    const sortedAnnotations = annotations
      .filter(function (annotation: FullAnnotation) {
        if (!(annotation?._local?.highlights.length > 0)) {
          console.log(
            "Skipping annotation ",
            annotation.id,
            " because it has no highlights"
          );
          return false;
        }
        return true;
      })
      .filter((annotation) =>
        options.user_list
          .map((u) => u.id.toString())
          .includes(annotation.user.id.toString())
      )
      .sort(paramFuncObject.sortComparison);
    data = sortedAnnotations.map(function (
      annotation: FullAnnotation,
      i: number
    ) {
      annotation._marginindex = i;
      return {
        annotation: annotation,
        location: paramFuncObject.sortDataMap(annotation),
      };
    });
    let currentLocation = 0;
    let _results: number[] = [];
    let addedHeights = 0;

    sortedAnnotations.forEach(function (annotation: FullAnnotation) {
      const highlight = annotation._local.highlights[0];
      const offsetTop = $(highlight)?.offset()?.top || 0;
      let newTop = offsetTop + displacement - wrapperOffset - addedHeights;
      if (currentLocation > newTop) {
        newTop = currentLocation;
      }
      const marginObject = createMarginObject(annotation, newTop);
      updateObjectLocation(annotation);
      currentLocation = newTop;
      addedHeights += marginObject.offsetHeight;
      _results.push(currentLocation);
    });
    return _results;
  }

  return {
    annotationCreated: function (annotation: FullAnnotation) {
      unfilteredAnnotationsList = [...unfilteredAnnotationsList, annotation];
      var currentdate = new Date();

      const highlight = annotation._local.highlights[0];
      const newObjectIndex = findIndexForNewObject(annotation);
      const marginElements = $(
        ".annotator-marginviewer-element"
      ).toArray() as HTMLElement[];
      const addedHeights = marginElements
        .slice(0, newObjectIndex)
        .reduce((acc, el) => acc + el.offsetHeight, 0);
      const offsetTop =
        (highlight ? highlight.offsetTop : 0) +
        displacement -
        wrapperOffset -
        addedHeights;

      annotation.created =
        ("0" + (currentdate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentdate.getDate()).slice(-2) +
        "-" +
        ("" + currentdate.getFullYear()).slice(2);

      const marginObject = createMarginObject(
        annotation,
        offsetTop,
        false,
        newObjectIndex < marginElements.length ? newObjectIndex : undefined
      );
      // const newObjectBottom = newObjectTop + marginObject.outerHeight(true);
      addNewObjectToData(annotation);
      // marginObject.fadeIn("fast");

      return onMarginSelected(marginObject);
    },
    annotationsLoaded: function (annotations: FullAnnotation[]) {
      unfilteredAnnotationsList = annotations;
      if (annotations.length == 0) {
        return [];
      }
      const filteredAnnotations = annotations.filter(
        (annotation: FullAnnotation) => annotation._local?.highlights
      );

      if (filteredAnnotations.length == annotations.length) {
        return initializeAnnotations(annotations);
      }
      setTimeout(() => {
        // sometimes not all annotations are loaded.
        initializeAnnotations(annotations);
      }, 500);
    },
    annotationUpdated: function (annotation: FullAnnotation) {
      const marginObject = $(
        `.annotator-marginviewer-element[data-annotation-id="${annotation.id}"]`
      )[0];
      annotation._marginObject = marginObject;
      $(marginObject).find(".annotator-marginviewer-text")[0].innerHTML =
        annotation.text || "";
    },
  };
}
